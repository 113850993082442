import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { IMPERSONATE } from 'mocks/mutations';
import useSetAuthTokenAndUser from 'data-hooks/useUserData/useSetAuthTokenAndUser';

const Impersonate = ({ hash }) => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const setAuthTokenAndUser = useSetAuthTokenAndUser();

  const [impersonate] = useMutation(IMPERSONATE, {
    onCompleted: async data => {
      const { user, errors } = data.impersonate;

      if (!user) {
        const message = get(errors, '0.message', null);
        setError(message || 'Login failed. Please try again');
      } else {
        const {
          access_token,
          email,
          first_name,
          group_id,
          id,
          is_designer,
          is_impersonate,
          last_name,
          user_discount,
        } = user;

        await setAuthTokenAndUser({
          access_token,
          email,
          first_name,
          group_id,
          id,
          is_designer,
          is_impersonate,
          last_name,
          refresh_token: '',
          user_discount,
        });

        history.push('/customer/dashboard');
      }
    },
  });

  useEffect(() => {
    impersonate({
      variables: { hash },
    });
  }, [hash, impersonate]);

  return (
    <div className="Impersonate">
      {error ? (
        <div className="text-lg font-bold min-h-[80vh] pt-48 text-red text-center">
          {error}
        </div>
      ) : (
        <div className="flex items-center justify-center text-lg font-bold min-h-[80vh]">
          loading...
        </div>
      )}
    </div>
  );
};

Impersonate.propTypes = {
  hash: PropTypes.string.isRequired,
};

export default Impersonate;
