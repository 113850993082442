import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

import isClient from 'commons/isClient';
import LoginContainer from 'components/consumer/LoginSidebar/login-container';
import Impersonate from '../impersonate';

const LoginPage = props => {
  const [overrideHash, setOverrideHash] = useState(null);

  useEffect(() => {
    const params = queryString.parse(window.location.search);
    if (params.type === 'override' && params.hash) {
      setOverrideHash(params.hash);
    }
  }, []);

  if (overrideHash) {
    return <Impersonate hash={overrideHash} />;
  }

  return (
    <div className="md:w-[28rem] md:mx-auto md:my-16 md:border md:border-solid md:border-gray-light7">
      <LoginContainer
        isLoginPage
        staticContext={props.staticContext}
        onLogin={() => {
          const { redirect, ...search } = isClient()
            ? queryString.parse(window.location.search)
            : {};
          const params = search ? queryString.stringify(search) : '';
          props.history.push(
            redirect ? `${redirect}${params ? `?${params}` : ''}` : '/'
          );
        }}
        variant="login-page"
      />
    </div>
  );
};

LoginPage.propTypes = {
  history: PropTypes.object,
  staticContext: PropTypes.object,
};

export default withRouter(LoginPage);
